/*General*/
.object-cover-top-center{
    object-fit: cover;
    object-position: top center;
}
.align-items-flex-end{
    align-items: flex-end !important;
}

/*Auth*/
.auth-input-wrap{
    margin: 0 0 1rem;
}
.auth-input-wrap p{
    width: 80%;
    margin: auto;
    text-align: left;
    padding-top: 3px;
}
.register__recaptcha{
    margin-bottom: 3px;
}
.register-form-checkbox .form-check .form-check-input{
    border: 1px solid #009688
}
.register-form-checkbox .form-check .form-check-input:checked{
    background-color: #009688;
    border-color: #009688;
}

/*Profile*/
.wrapper__my-profile-tabs{
    align-items: start;
}
.wrapper__tabs-content{
    margin: 0 0 40px 0;
}
.my-profile__label{
    padding-bottom: 15px;
}

/*About Us*/
.ourteam__img{
    border-radius: 7px;
}

.contact-us .row{
    max-width: initial;
}

/*Book Details*/
.book-details-sharebar{
    top: 23% !important;
}
.reviews-detail-container{
    width: 100%;
}
.about-author__name a{
    color: #000000;
}
.about-author__name a:hover{
    color: #F06565 !important;
    transition: .5s;
}
.author-books{
    align-items: end !important;
}
.no-books-img{
    width: initial !important;
}
.no-books-title{
	font-family: authorMedium !important;
}
.no-books-sub{
    color: #333333;
}

/*Event and Promotion*/
.eventpromo-list .row{
    max-width: initial;
    width: initial;
    /* margin: initial; */
}
.eventpromo-list .row .row{
    max-width: initial;
    width: initial;
    /* margin: initial; */
}
.showing-promo-events{
    font-size: 30px;
}
.event-sidebar{
    width: initial;
}
.event-card img{
    max-height: 288px;
}
.event-title{
    font-size: 20px;
    font-family: authorSemiBold;
    color: #333333;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event-category{
    font-size: 16px;
    font-weight: 400;
    color: #009688;
}
.event-category span{
    color: #635858;
}
.event-description{
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*Event and Promotion Details*/
.share-event{
    top: 20% !important;
}
.share-event-label{
    line-height: 40px;
}
.btn-blue{
    background-color: #3650DA;
}
.btn-disabled{
    background-color: #B1B7D6 !important;
}
.btn-event-details{
    max-width: 261px;
    width: 100%;
	font-family: authorSemiBold !important;
}
.mobile-share .row{
    width: initial;
    max-width: initial;
    background-color: #ddd;
}
.socmed-icon img{
    border-radius: 50%;
    background-color: white;
}
.seemore-events-img{
    width: 300px;
    height: 300px;
}
.event-details-img img{
    height: auto !important;
}

/*FAQ*/
.wrapper__faq .accordion-button:not(.collapsed) {
    box-shadow: none;
}
.wrapper__faq .accordion-item h2 a {
    color: #000000;
    font-size: 20px;
    font-family: authorMedium;
    line-height: 22px;
}

/*News*/
.news-list .row{
    max-width: initial;
    width: initial;
    /* margin: initial; */
}
.news-list .row .row{
    max-width: initial;
    width: initial;
    /* margin: initial; */
}
.news-sidebar{
    width: initial;
}
.showing-news{
    font-size: 30px;
}
.news-img{
    height: 220px;
    width: 270px;
    object-fit: cover;
    object-position: top center;
}
.news-title{
    height: initial !important;
    margin-top: initial !important;
}

/*Error*/
.error-container{
    max-width: 100%;
}

/*Subscription*/
.alert-subs-btn:hover{
    background-color: inherit !important;
}
.subs-row{
    max-width: initial;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
   @media only screen and (max-width: 321px){
    /*Micro Mobile Styles*/
    .btn-event-details{
        max-width: 100%;
    }

    .news-title{
        -webkit-line-clamp: 3 !important;
    }

    .checkmark{
        bottom: 16%;
    }
   }

   @media only screen and (max-width: 380px){
    /*Mini Mobile Styles*/
    .btn-event-details{
        max-width: 100%;
    }

    .news-title{
        -webkit-line-clamp: 3 !important;
    }

    .checkmark{
        bottom: 16%;
    }
   }
   @media only screen and (orientation:landscape) and (max-height: 380px){

   }
   @media only screen and (min-width:381px)  and (max-width: 480px) {
    /* Mobile styles */
        .event-card img{
            max-height: 322px;
        }
        .showing-promo-events{
            margin-top: 1rem;
            margin-bottom: 0;
        }
        .btn-event-details{
            max-width: 100%;
        }

        .news-title{
            -webkit-line-clamp: 3 !important;
        }

        .checkmark{
            bottom: 16%;
        }
    }
    @media only screen and (orientation:landscape) and (min-height:381px)  and (max-height: 480px){
   
    }
     @media screen and (min-width:481px) and (max-width:1023px) {
    /* Tablet styles */
        .event-card img{
            max-height: 160px;
        }
        .share-event{
            top: 15% !important;
        }
        .event-details-img{
            width: 95% ;
        }

        .news-img{
            width: 150px;
            height: 100px;
        }

        .book-details-sharebar{
            top: 17% !important;
        }

    }
    @media only screen and (orientation:landscape) and (min-height:760px) and (max-height:1023px){
 
    }
    @media screen and (min-width:1024px) and (max-width:1139px){
        /*Notebook styles*/
        .event-card img{
            max-height: 227px;
        }
        .share-event{
            top: 25% !important;
        }
        .event-details-img{
            width: 95% ;
        }

        .book-details-sharebar{
            top: 27% !important;
        }
        .gpu__content.detail-buku{
            margin: initial;
            margin-left: 60px;
        }
    }
     @media only screen and (min-width: 1200px){
    /* Desktop styles */
        .gpu__content.update-book img{
            width: 250px !important;
            height: 250px !important;
        }
    }
     @media only screen and (min-width: 1400px){
    /* Desktop styles */
        .gpu__content.update-book img{
            width: 300px !important;
            height: 300px !important;
        }
    }

.author.desc p {
    color: #FFF !important;
}